<template>
    <ODataLookup :data-object="dsDO" :noClear="true">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
        </template>
        <OColumn field="ID" width="100"></OColumn>
        <OColumn field="Name" width="200"></OColumn> 
    </ODataLookup>
</template>

<script setup>
    import { defineProps, ref, computed } from 'vue';
    import { ODataLookup } from 'o365-datalookup';
    const props = defineProps({
        textInput: Boolean,
        is: String,
        textInputValue: String,        
    });

    const dsDO = $getOrCreateDataObject({
        id: 'dsDO' + crypto.randomUUID(),
        viewName: 'aviw_Complete_RespCodesT',
        maxRecords: 25,
        whereClause: "MyLanguage=1",
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "ID", type: "string" },
            { name: "Name", type: "string" }
        ]
    });
</script>